import { DecimalPipe } from '@angular/common';
import { Component, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit';
import { Observable } from 'rxjs';
import { QuestionModalComponent } from './modals/question-modal/question-modal.component';
import { QDataObject } from './dataModels';
import { FilterType, QuerstionService } from './question.service';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { DeleteModalComponent } from './modals/delete-modal/delete-modal.component';
import { QData } from '@specialforce/spftypes';
import saveAs from "file-saver"
import * as XLSX from "xlsx";
import * as moment from "moment";

@Component(
  {
    selector: 'app-table-complete',
    templateUrl: 'table-complete.component.html',
  })
export class TableCompleteComponent implements OnInit {
  modalRef: MdbModalRef<QuestionModalComponent>;
  @Output() public _selectQuestion: QData = null;
  @Output() public: QData;
  questions$: Observable<QData[]>;
  total$: Observable<number>;
  public columnsArray: string[] = ["status", "creationTimestamp", "text", "creatorName", "assignedToIdentifier", "lastAnswered"];
  public columnToLang: Map<string, string> = new Map(
    [
      ["connectionstates", "Verbindung"],
      ["status", "Status"],
      ["creationTimestamp", "Eingangsdatum"],
      ["text", "Text"],
      ["creatorName", "Von"],
      ["assignedToIdentifier", "Zugewiesen an"],
      ["lastAnswered", "Letzte Antwort durch"],
      ["sessionIdentifier", "Sitzung"]
    ]
  );
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  constructor(public service: QuerstionService, private modalService: MdbModalService) {
    if (this.service.applicationMode === 'full' && this.columnsArray.indexOf("sessionIdentifier") < 0) {
      this.columnsArray.unshift("connectionstates")
      this.columnsArray.unshift("sessionIdentifier")
    }
    this.questions$ = service.questions$;
    this.total$ = service.total$;
  }
  ngOnInit() {
    // DEBUG A WINDOW initial
    //    this.questions$.subscribe((q) => { if (q[0]) { this.openModal(q[0], "question") } });


  }

  exportXLS() {
    const questions = this.service.getNonPaginatedFilteredquestions()
    const headerRow = ["Fragendatum", "Fragensteller", "Frage", "Antwort", "Antwortdatum", "Beatworter", "Öffentliche Beatwortung"]
    if (this.service.applicationMode == 'full') {
      headerRow.push()
    }
    const sheetData: (string | boolean | Date)[][] = []

    questions.forEach((q) => {
      q.answers.forEach((a) => {

        const ds = [
          new Date(q.creationTimestamp),
          q.creatorName,
          q.text,
          a.text,
          new Date(a.creationTimestamp),
          a.creatorName,
          a.isPublicAnswer
        ]
        sheetData.push(ds)
      })
    })
    const sheet = XLSX.utils.aoa_to_sheet(sheetData)

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet, "FragenUndAntworten");
    const data = XLSX.writeFile(wb, "FragenUndAntworten.xlsx", { bookType: 'xlsx', bookSST: true, compression: true })
  }
  exportTXT() {
    let str = "";
    const questions = this.service.getNonPaginatedFilteredquestions()
    questions.forEach((q) => {
      if (this.service.sidToShowNames.get(q.sessionIdentifier).participant) {
        str += q.creatorName + ": " + q.text + "\r\n"
      } else {
        str += "(Frage): " + q.text + "\r\n"
      }

      q.answers.forEach((a) => {
        if (this.service.sidToShowNames.get(q.sessionIdentifier).moderator) {
          str += "  " + a.creatorName + (a.isPublicAnswer ? " (Öffentlich)" : " (Privat)") + ": " + a.text + "\r\n"
        } else {
          str += (a.isPublicAnswer ? " (Öffentlich)" : " (Privat)") + ": " + a.text + "\r\n"
        }
      })
      str += "\r\n"
    })

    var blob = new Blob([str], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "FragenUndAntworten.txt");

  }
  openModal(question, type: "question" | "delete") {

    let component = null;
    let modalClass = 'modal-xl'
    switch (type) {
      case "question":
        component = QuestionModalComponent;
        break;

      case "delete":
        component = DeleteModalComponent;
        modalClass = ''
        break;
    }

    this._selectQuestion = question;
    this.modalRef = this.modalService.open(component, {
      modalClass,
      data: {
        question: this._selectQuestion,
        openModal: () => this.openModal(question, "delete"),
        tableCompleteComponent: this
      }
    })

  }

  public unselectQuestion() {
    this._selectQuestion = null;
  }



  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }
  public clickShow(question: QDataObject) {
    this.service.workingOn(question);
    this.openModal(question, 'question');
  }
}