import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { QData } from '@specialforce/spftypes';
import { MdbModalRef } from 'mdb-angular-ui-kit';
import { QDataObject } from '../../dataModels';

import { QuerstionService } from '../../question.service';
import { TableCompleteComponent } from '../../table-complete.component';

@Component({
  selector: 'app-chat',
  templateUrl: './question-modal.component.html',
  styles: [
  ]

})
export class QuestionModalComponent implements OnInit, OnDestroy {
  question: QDataObject;
  openModal: Function;
  public tableCompleteComponent: TableCompleteComponent;
  constructor(public modalRef: MdbModalRef<QuestionModalComponent>) { }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.tableCompleteComponent.service.workingOff(this.question);
  }
  sendAnswer(textarea: HTMLInputElement, isPublic: boolean) {
    if (textarea.value && textarea.value.length > 0) {
      this.tableCompleteComponent.service.answer(this.question, textarea.value, isPublic);
      textarea.value = '';
    }
  }

  public getUserAgent() {
  }

}
