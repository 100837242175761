import { Component, Input, OnInit } from '@angular/core';
import { Filter } from '../../filterbuttons/filterbuttons.component';
import { FilterType, QuerstionService } from '../question.service';

@Component({
  selector: 'app-filterbutton',
  templateUrl: './filterbutton.component.html',
  styles: [
  ]
})
export class FilterbuttonComponent implements OnInit {
  @Input() public currentFilter: string;
  @Input() public filterOption: string;
  @Input() public bootstrapStyle: string = "primary";
  constructor(public service: QuerstionService) { }

  ngOnInit(): void {
  }

}
