import { Component, Input, OnInit } from '@angular/core';
import { QData } from '@specialforce/spftypes';

@Component({
  selector: 'app-question-renderer',
  templateUrl: './question-renderer.component.html',
  styles: [
  ]
})
export class QuestionRendererComponent implements OnInit {
  @Input() question: QData;
  @Input() showAnswers = false;
  constructor() { }

  ngOnInit(): void {
  }

}
