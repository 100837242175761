import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuestionlistComponent } from './questionlist/questionlist.component';

import { TableCompleteComponent } from './questionlist/table-complete/table-complete.component';
import { FormsModule } from '@angular/forms';
import { FilterbuttonComponent } from './questionlist/table-complete/filterbutton/filterbutton.component';
import { MdbDropdownModule, MdbModule, MdbRippleModule } from 'mdb-angular-ui-kit';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuestionModalComponent } from './questionlist/table-complete/modals/question-modal/question-modal.component';
import { NgbdSortableHeader } from './questionlist/table-complete/sortable.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FilterbuttonsComponent } from './questionlist/filterbuttons/filterbuttons.component';
import { DeleteModalComponent } from './questionlist/table-complete/modals/delete-modal/delete-modal.component';
import { QuestionRendererComponent } from './question-renderer/question-renderer.component';
import { AssignedToIdentifierComponent } from './assigned-to-identifier/assigned-to-identifier.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgQaQuestionRendererModule } from '@specialforce/ng-common-components';
import { TokenResolver } from './shared/TokenResolver';
import { EmptyComponent } from './empty/empty.component';
import { SessionListComponent } from './session-list/session-list.component';
import { SessionListRowComponent } from './session-list/session-list-row/session-list-row.component';
import { LightModeResolver } from './shared/LightModeResolver';
import { FullModeResolver } from './shared/FullModeResolver';
import { ConnectSocketDataIndikatorComponent } from './questionlist/connect-socket-data-indikator/connect-socket-data-indikator.component';
import { ConnectUserDataIndikatorComponent } from './questionlist/connect-user-data-indikator/connect-user-data-indikator.component';

@NgModule({
  declarations: [
    AppComponent,

    QuestionlistComponent,
    TableCompleteComponent,
    FilterbuttonComponent,
    QuestionModalComponent,
    NgbdSortableHeader,
    FilterbuttonsComponent,
    DeleteModalComponent,
    QuestionRendererComponent,
    AssignedToIdentifierComponent,
    EmptyComponent,
    SessionListComponent,
    SessionListRowComponent,
    ConnectSocketDataIndikatorComponent,
    ConnectUserDataIndikatorComponent
  ],

  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,

    MdbModule,
    MdbDropdownModule,
    BrowserAnimationsModule,
    MdbRippleModule,
    NgbModule,
    CommonModule,
    NgQaQuestionRendererModule,
    MdbDropdownModule

  ],
  providers: [
    DecimalPipe,
    TokenResolver,
    LightModeResolver,
    FullModeResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
