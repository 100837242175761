import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { EmptyComponent } from './empty/empty.component';
import { QuestionlistComponent } from './questionlist/questionlist.component';
import { FullModeResolver } from './shared/FullModeResolver';
import { LightModeResolver } from './shared/LightModeResolver';
import { TokenResolver } from './shared/TokenResolver';

const routes: Routes = [
  {
    path: 'list/:token',
    component: QuestionlistComponent,
    resolve: {
      token: LightModeResolver
    }
  },
  {
    path: 'list',
    component: QuestionlistComponent,
    resolve: {
      token: FullModeResolver
    }
  },
  {
    path: '',
    redirectTo: "list",
    pathMatch: "full"
  },

  {
    path: ':token',
    component: EmptyComponent,
    resolve: {
      token: TokenResolver
    }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {






}
