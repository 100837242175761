import { UserListData } from '@specialforce/spftypes';
import { Input } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-connect-user-data-indikator',
  templateUrl: './connect-user-data-indikator.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectUserDataIndikatorComponent implements OnInit {
  @Input() userListDatas: UserListData[]
  constructor() { }

  ngOnInit(): void {
  }

}
