import { Injectable } from '@angular/core';
import { Socket } from 'socket.io-client';
import { io } from "socket.io-client";
import { ClientToServerEventsQA, ModeratorUser, ServerToClientEventsQA, QData, ShowNames } from '@specialforce/spftypes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private static socket_: Socket<ServerToClientEventsQA, ClientToServerEventsQA>;
  private appMode: "light" | "full";
  public lightModeToken: string;
  constructor() {
  }

  public set applicationMode(mode: "light" | "full") {
    this.appMode = mode
  }

  public get applicationMode(): "light" | "full" {
    return this.appMode
  }

  public get socket(): Socket<ServerToClientEventsQA, ClientToServerEventsQA> {
    if (!SocketService.socket_) {
      if (window.location.origin.includes('localhost')) {
        SocketService.socket_ = io(environment.url, { path: '/api/socket.io', auth: { 'token': 'bahnhof' } })
      } else {
        SocketService.socket_ = io(window.location.origin, { path: '/api/socket.io', auth: { 'token': 'bahnhof' } })
      }
    }
    return SocketService.socket_;
  }

  public joinModeratorClient(token: string): Promise<boolean> {
    console.time("joinModeratorClient")
    return new Promise<boolean>((resolve, reject) => {
      this.socket.emit('joinModeratorClient', token, (worked: boolean) => {
        console.timeEnd("joinModeratorClient")
        resolve(worked)
      })
    })
  };

  public leaveModeratorClient(token: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.socket.emit('leaveModeratorClient', token, (worked: boolean) => {
        resolve(worked)
      })
    })
  };

  public moderatorList(): Promise<ModeratorUser[]> {
    console.time("LoadModeratorList")
    return new Promise<ModeratorUser[]>((resolve, reject) => {
      this.socket.emit('moderatorlist', (moderators: ModeratorUser[]) => {
        console.timeEnd("LoadModeratorList")
        resolve(moderators)
      })
    })
  }

  public getQuestionList(): Promise<{ questions: QData[], workedByArray: any[] }> {
    console.time("getQuestionList")
    return new Promise<{ questions: QData[], workedByArray: any[] }>((resolve, reject) => {
      this.socket.emit('getQuestionList', (questions: QData[], workedByArray: any[]) => {
        console.timeEnd("getQuestionList")
        resolve({ questions, workedByArray })
      })
    })
  }

  public getShowNames(sessionIdentifier: string): Promise<ShowNames> {
    return new Promise<ShowNames>((resolve, reject) => {
      this.socket.emit('getShowNamesModeratorView', sessionIdentifier, (showNames: ShowNames) => {
        resolve(showNames)
      })
    })
  };

}
