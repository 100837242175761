import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { UserListData } from '@specialforce/spftypes';
import { UAParser } from 'ua-parser-js';

@Component({
  selector: 'app-connect-socket-data-indikator',
  templateUrl: './connect-socket-data-indikator.component.html',
  styles: [
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectSocketDataIndikatorComponent implements OnInit {

  @Input() uData: UserListData
  constructor() { }

  ngOnInit(): void {
  }

  public getUserAgentStr(agent: string): string {
    const parsed = new UAParser(agent)
    return (agent.indexOf("ConnectApp") >= 0 ? "App " : "Browser ") + parsed.getBrowser().name + "(" + parsed.getBrowser().version + ") " + parsed.getOS().name + "(" + parsed.getOS().version + ")"
  }
}
