<form>
  <div class="div">
    <div
      class=""
      *ngIf="service.loading$ | async; else x"
    ><i class="ri-loader-2-fill "></i></div>
    <ng-template #x>
      <i class="ri-loader-2-fill text-white"></i>
      <div
        (click)="exportTXT()"
        mdbTooltip="Text Export"
        class="float-right btn  btn-sm btn-outline-primary mb-1"
      ><i class="ri-file-text-fill"></i></div>
      <div
        (click)="exportXLS()"
        mdbTooltip="Excel Export"
        class="float-right btn btn-sm btn-outline-primary mr-1 mb-1"
      >
        <i class="ri-file-excel-fill"></i>
      </div>
    </ng-template>

  </div>

  <table
    class="table table-striped table-hover"
    mdbTable
    mdbTableScroll
    scrollY="true"
    maxHeight="200"
  >
    <thead>
      <tr>
        <ng-container *ngFor="let col of columnsArray">

          <th
            class="cursor-pointer "
            [width]="(col == 'text') ? '20%':'auto'"
            [style.width]="(col == 'text') ? '20% !important':'auto'"
            [sortable]="col"
            (sort)="onSort($event)"
          >
            {{columnToLang.get(col) || col}}
          </th>
        </ng-container>
        <th
          scope="col"
          width="400px"
        > &nbsp;</th>

      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let question of questions$ | async"
        [ngClass]="{'table-primary':(this._selectQuestion == question), 'table-warning': question.workedByStr }"
      >
        <td *ngFor="let col of columnsArray">
          <span [ngSwitch]="col">
            <p *ngSwitchCase="'sessionIdentifier'">
              {{this.service.getSessionIdentifierNamedByQuestion(question)}}
            </p>
            <p *ngSwitchCase="'connectionstates'">
              <app-connect-user-data-indikator
                [userListDatas]="this.service.userListHelper.getUserListSessionAndUserId(question.sessionIdentifier, question.creatorIdentifier)"
              >
              </app-connect-user-data-indikator>
            </p>

            <p *ngSwitchCase="'creationTimestamp'">
              {{question[col] | date:'yyyy-MM-dd HH:mm:ss'}}
            </p>
            <app-assigned-to-identifier
              *ngSwitchCase="'assignedToIdentifier'"
              [question]="question"
            >
            </app-assigned-to-identifier>
            <p *ngSwitchDefault>
              <ngb-highlight
                [result]="question[col]"
                [term]="service.searchTerm"
              > </ngb-highlight>
            </p>
          </span>
        </td>
        <td
          width="0px"
          class="text-right"
          style="width:0;"
        >

          <div
            class="btn btn-sm btn-primary mr-1"
            (click)="clickShow(question)"
          >ansehen
          </div>


          <div
            class="btn btn-outline-danger btn-sm mr-1"
            *ngIf="this.service.currentFilter !='deleted'"
            (click)="this.openModal(question,'delete')"
          >Löschen</div>

          <div
            class="btn btn-outline-danger btn-sm mr-1"
            *ngIf="this.service.currentFilter =='deleted'"
            (click)="this.service.unDelete(question)"
          >
            <i
              class="ri-arrow-go-back-line"
              style="position:relative;top:3px"
            ></i>
            Wiederherstellen
          </div>
        </td>


      </tr>
    </tbody>
  </table>

  <div class="d-flex justify-content-between p-2">

    <ngb-pagination
      [collectionSize]="(total$ | async)!"
      [(page)]="service.page"
      [pageSize]="service.pageSize"
    >
    </ngb-pagination>

    <select
      class="custom-select"
      style="width: auto"
      name="pageSize"
      [(ngModel)]="service.pageSize"
    >
      <option [ngValue]="25">25 items per page</option>
      <option [ngValue]="50">50 items per page</option>
      <option [ngValue]="100">100 items per page</option>
    </select>
  </div>

</form>