import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { QuerstionService } from '../questionlist/table-complete/question.service';
import { ConnectSocketData } from '@specialforce/spftypes';
import { SocketService } from './SocketService';

@Injectable()
export class FullModeResolver implements Resolve<any> {
    constructor(private route: ActivatedRoute, private router: Router, private socketService: SocketService, private service: QuerstionService) {
    }

    async resolve(route: ActivatedRouteSnapshot) {
        this.socketService.applicationMode = "full"
        this.socketService.lightModeToken = null
        return
    }
}
