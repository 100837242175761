import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../shared/NotificationService';
import { SocketService } from '../shared/SocketService';
import { QuerstionService } from './table-complete/question.service';
@Component({
  selector: 'app-questionlist',
  templateUrl: './questionlist.component.html',
  styleUrls: ['./questionlist.component.scss'],
})

export class QuestionlistComponent implements OnInit, AfterViewInit {
  @ViewChild('header', { read: ElementRef, static: false }) public headerRef: ElementRef;
  public headerHeight;
  public window = window;
  public sessionsMenu = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.headerHeight = this.headerRef?.nativeElement.offsetHeight;
  }
  @HostListener('window:storage', ['$event'])
  onLocalStorageChange(event: StorageEvent) {
    console.log('local storage change', event, event.key === 'QA-External-Moderatortokens');
    if (event.key === 'QA-External-Moderatortokens') {
      const newArray: string[] = JSON.parse(event.newValue);
      const oldArray: string[] = JSON.parse(event.oldValue);
      this.service.tokenArrayChanged(oldArray, newArray);
    }
  }
  constructor(public service: QuerstionService, private socketService: SocketService, private aRoute: ActivatedRoute, private notiService: NotificationService) {


  }

  ngAfterViewInit() {
    let f = () => {
      this.window.dispatchEvent(new Event('resize'));
    }
    setTimeout(f, 1);
  }

  ngOnInit(): void {
    this.onResize();
  }

  changedNotification() {
    localStorage.setItem('QA-External-Notifications', JSON.stringify({ audio: this.service.audionotification, desktop: this.service.desknotification }));
  }
  async checkIfDesktopAllowed(i?: boolean) {
    if (this.service.desknotification === true) {
      if (this.notiService.notificationsPossible && Notification.permission === 'granted') {
        this.changedNotification();
      } else if (this.notiService.notificationsPossible && !i) {
        const permission: NotificationPermission = await this.notiService.requestPermission();
        this.checkIfDesktopAllowed(true);
      } else {
        this.service.desknotification = false;
      }
    }
    if (this.service.desknotification === false) this.changedNotification();
  }

}
