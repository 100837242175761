<header>
  <div class="navbar  box-shadow fixed-top bg-white text-center " #header>
    <!--div>{{this.service.userListHelper.userList | json}}</div!-->
    <app-session-list class="w-100" *ngIf="this.sessionsMenu"></app-session-list>

    <table border="0" class="w-100 d-table">
      <tr>
        <td style="text-align:left;white-space: nowrap; " width=10%>
          <app-filterbuttons></app-filterbuttons>
        </td>
        <td style="text-align:left;">
          <input class="form-control input-sm form-control-sm w-100" style="position: relative;top:-1px" type="text"
            placeholder="Suche" name="searchTerm" [(ngModel)]="service.searchTerm" />
        </td>
        <td style="width:250px;padding-left:30px" class="text-right">
          <div class="form-check form-switch d-inline mr-3">
            <input class="form-check-input" type="checkbox" id="audionotification"
              [(ngModel)]="service.audionotification" (ngModelChange)="changedNotification()">
            <label class="form-check-label ml-3" for="audionotification" mdbTooltip="Audio Benachrichtigung">
              <i class="ri-notification-4-line"></i>
            </label>
          </div>
          <div class="form-check form-switch d-inline">
            <input class="form-check-input" type="checkbox" id="desknotification" [(ngModel)]="service.desknotification"
              (ngModelChange)="checkIfDesktopAllowed()">
            <label class="form-check-label ml-3" for="desknotification" mdbTooltip="Desktop Benachrichtigung">
              <i class="ri-notification-badge-line"></i></label>
          </div>
          <div class="btn btn-sm  px-3 py-0 ml-3" [ngClass]="{'btn-primary': this.sessionsMenu}"
            (click)="this.sessionsMenu= !this.sessionsMenu">
            <i class="ri-stack-line"></i>
          </div>
        </td>
      </tr>
    </table>
    <div>
    </div>
    <div class="text-left">

    </div>
  </div>
</header>

<div class="container-fluid  pt-5" [style.margin-top.px]=headerHeight>
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">











          <app-table-complete></app-table-complete>
        </div>
      </div>
    </div>
  </div>
</div>