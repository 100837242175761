import { OnChanges } from '@angular/core';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ModeratorUser } from '@specialforce/spftypes';
import { MODERATORS, QDataObject } from '../questionlist/table-complete/dataModels';
import { QuerstionService } from '../questionlist/table-complete/question.service';

@Component({
  selector: 'app-assigned-to-identifier',
  templateUrl: './assigned-to-identifier.component.html',
  styles: [
  ]
})
export class AssignedToIdentifierComponent implements OnInit, OnChanges {

  @Input() public question: QDataObject;
  public moderators: ModeratorUser[] = []
  constructor(public service: QuerstionService) {
    service.moderatorsChanged.subscribe(() => this.updateModeratorList())
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateModeratorList()
  }
  public updateModeratorList() {
    this.moderators = MODERATORS.filter(moderator => moderator.sessionIdentifier === this.question.sessionIdentifier)
  }

  ngOnInit(): void {

  }
  onSelect(value) {
    let user: ModeratorUser = this.moderators.find(m => m.userId === value);
    if (!user) {
      user = { userId: '', userName: '', sessionIdentifier: '' };
    }
    this.service.questionAssigned(this.question, user.userId, user.userName);
  }

}
