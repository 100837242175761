import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { QuerstionService } from '../questionlist/table-complete/question.service';
import jwt_decode from 'jwt-decode';
import { ConnectSocketData } from '@specialforce/spftypes';
import { SocketService } from './SocketService';

@Injectable()
export class LightModeResolver implements Resolve<any> {
    constructor(private route: ActivatedRoute, private router: Router, private socketService: SocketService, private service: QuerstionService) {
    }

    async resolve(route: ActivatedRouteSnapshot) {
        const token: string = route.params.token;
        const tokenObject: ConnectSocketData & { exp: number } = jwt_decode(token)

        console.log("tokenobject fullmode", tokenObject.fullMode)
        this.socketService.applicationMode = "light"
        this.socketService.lightModeToken = token
        console.log("setting applicaitonmode to light")
        return token
    }
}
