import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectSocketData } from '@specialforce/spftypes';
import { QuerstionService } from './questionlist/table-complete/question.service';
import { NotificationService } from './shared/NotificationService';
import { SocketService } from './shared/SocketService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @ViewChild('notificationRequest') notificationRequest;
  @ViewChild('sosModal') sosModal;
  title = 'QA-external';
  msg: string = '';
  tokenObject: ConnectSocketData;

  constructor(public service: QuerstionService, public notificationService: NotificationService, private modalService: NgbModal, private socketService: SocketService) {
  }


  public getRoomLinkFromToken(tokenObject: any) {
    if (tokenObject.roomLink != null) {
      return tokenObject.roomLink
    }
    return tokenObject.connectDomain + "/" + tokenObject.connectRoomPath
  }
  public ngAfterViewInit() {
    if (this.notificationService.showNotificationRequest()) {
      this.modalService.open(this.notificationRequest).result.then(
        (result: string) => {
          // Modal window closed by executing "modal.close"
          if (result === 'accept') {
            this.notificationService.requestPermission();
          } else {
            this.notificationService.disableAppPermission();
          }
        },
        (reason) => {
          // Modal window closed by clicking somewhere else
        }
      );
    }
    this.socketService.socket.on('requestHelp', (message: string, sessionIdentifier: string) => {
      if (this.service.sidToTokenObject.has(sessionIdentifier)) {
        this.tokenObject = this.service.sidToTokenObject.get(sessionIdentifier);
        this.msg = message;
        this.service.playSoundIfAudioOn();
        this.service.sendNotificationIfAllowed('Neue Hilfeanfrage');
        this.modalService.open(this.sosModal).result.then(
          (result: string) => {
            // Modal window closed by executing "modal.close"
            this.tokenObject = undefined;
            this.msg = '';
          },
          (reason) => {
            // Modal window closed by clicking somewhere else
            this.tokenObject = undefined;
            this.msg = '';
          }
        );
      }
    });
  }
}
