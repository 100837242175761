<div class="modal-header">
  <h5 class="modal-title"
      id="exampleModalLabel">Frage Löschen?</h5>
  <button type="button"
          class="btn-close"
          aria-label="Close"
          (click)="modalRef.close()"></button>
</div>
<div class="modal-body">

  <app-question-renderer [question]=question></app-question-renderer>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-secondary btn-sm"
          (click)="modalRef.close()">
    Schließen
  </button>
  <button type="button"
          class="btn btn-danger  btn-sm"
          (click)="this.tableCompleteComponent.service.delete(question);modalRef.close()">Löschen</button>
</div>
