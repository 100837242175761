import { Component, OnInit } from '@angular/core';
import { FilterType, QuerstionService } from '../table-complete/question.service';
export type Filter = {
  name: string;
  type: FilterType;
  styleClass: string;
}

@Component({
  selector: 'app-filterbuttons',
  templateUrl: './filterbuttons.component.html',
  styles: [
  ]
})
export class FilterbuttonsComponent implements OnInit {
  public filters: Filter[] = [
    { name: "Alle", type: "all", styleClass: "secondary" },
    { name: "Unbeantwortet", type: "open", styleClass: "danger" },
    { name: "Offen, nicht zugewiesen", type: "new", styleClass: "warning" },
    { name: "Offen, mir zugewiesen", type: "my", styleClass: "warning" },
    { name: "Beantwortet", type: "done", styleClass: "success" },
    { name: "Gelöscht", type: "deleted", styleClass: "secondary" },

  ];
  constructor(public service: QuerstionService) { }

  ngOnInit(): void {
  }
  setfilter(type: FilterType) {

    this.service.filter(type);

  }

}
