import { AData, ModeratorUser, QData } from "@specialforce/spftypes";
import { LoremIpsum } from "../../../../node_modules/lorem-ipsum";
import { environment } from './../../../environments/environment';

const lorem = new LoremIpsum({
    sentencesPerParagraph: {
        max: 8,
        min: 4
    },
    wordsPerSentence: {
        max: 16,
        min: 4
    }
});
export class QDataObject {
    private _data: QData;
    public workedByArray: any[] = [];
    constructor(data: QData) { this._data = data; }
    public get id() { return this._data._id; }

    public get sessionIdentifierName(): string {
        return this._data.sessionIdentifier
    }

    public delete() {
        this._data.isDeleted = true;
    }
    public unDelete() {
        this._data.isDeleted = false;
    }
    public addAnswer(answer: AData) {
        this._data.answers.push(answer);
    }
    public assignedTo(assignedId: string) {
        this._data.assignedToIdentifier = assignedId;
    }
    public assignToName(assignedName: string) {
        this._data.assignedToName = assignedName;
    }
    public get workedByStr(): string {
        if (this.workedByArray.length > 0) {
            return this.workedByArray.map(w => w.userName).join(', ')
        }
        return undefined;
    }
    public get status() {
        if (this._data.isDeleted) return "Gelöscht"
        if (this.workedByStr) return "Bearbeitung"
        if (this.answers.length == 0) {
            if (this.isAssigned) return "Zugewiesen"
            return "Neu"
        }
        return "Beantwortet"



    }
    public get sessionIdentifier() { return this._data.sessionIdentifier; }
    public get creatorIdentifier() { return this._data.creatorIdentifier; }
    public get creatorName() { return this._data.creatorName; }
    public get creationTimestamp() { return this._data.creationTimestamp; }
    public get text() { return this._data.text; }
    public get assignedToIdentifier() { return this._data.assignedToIdentifier; }
    public set assignedToIdentifier(assignedId: string) { this._data.assignedToIdentifier = assignedId; }
    public get assignedToName() { return this._data.assignedToName; }
    public set assignedToName(assignedName: string) { this._data.assignedToName = assignedName; }
    public get isDeleted() { return this._data.isDeleted; }
    public get answers() { return this._data.answers; }
    public get isAssigned() { return this._data.assignedToIdentifier != ""; }
    public get isAnswered() { return this._data.answers.length > 0 }
    public get lastAnswered() {
        if (!this.isAnswered) {
            return "-"
        }
        return this._data.answers[this._data.answers.length - 1].creatorName
    }
    public get lastTime() { return Math.max(this._data.creationTimestamp, ...this._data.answers.map(a => a.creationTimestamp)) }


}

function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}
function randomFrom(arr) {
    let id = getRandomInt(arr.length)
    return arr[id];

}


export const QUESTIONS: QDataObject[] = [];
export const MODERATORS: ModeratorUser[] = [];
const dummyAnswer = {
    text: "ANSWER 123",
    creatorName: "the answer guy"
}
if (environment.demoData) {
    for (let i = 0; i < 121; i++) {
        QUESTIONS.push(
            new QDataObject(
                {
                    _id: "id",
                    creatorIdentifier: "markoId",
                    creatorName: randomFrom(["marko", "Thomas", "jonas", "mirko"]),
                    creationTimestamp: new Date().getTime(),
                    text: lorem.generateSentences(randomFrom([3, 4])),
                    assignedToIdentifier: randomFrom(["ICH", ""]),
                    isDeleted: randomFrom([true, false]),
                    answers: randomFrom([[], [dummyAnswer]]),



                }

            ))

    }
}

