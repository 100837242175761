<div class="mb-4 border-bottom-1">

  <div class=" alert alert-light  d-inline-block  shadow mr-4">
    <b>Frage von {{question.creatorName }}:</b><br>
    <i> {{question.text }}</i>
    <br>
    <span class="text-muted d-block text-right">
      <span mdbTooltip="{{question.creationTimestamp | date:'yyyy-MM-dd H:mm'}}">
        {{question.creationTimestamp | date:'H:mm'}}</span>
    </span>
  </div>


  <ng-container *ngIf="this.showAnswers">
    <div class="text-right"
         *ngFor="let answer of question.answers">
      <div class=" alert alert-success  d-inline-block  shadow ml-4">
        <span class="badge badge-primary ml-n3 mt-1 mr-4"
              style="float:left"
              *ngIf="answer.isPublicAnswer">Öffentlich</span><b>{{answer.creatorName }}</b>

        <br>
        {{answer.text}}<br>
        <span mdbTooltip="{{answer.creationTimestamp | date:'yyyy-MM-dd H:mm'}}">
          <span class="text-muted"> {{answer.creationTimestamp | date:'H:mm'}} </span></span>
      </div>
    </div>



  </ng-container>

</div>
