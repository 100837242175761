import { Component, OnInit } from '@angular/core';
import { ShowNames } from '@specialforce/spftypes';
import { QuerstionService } from '../questionlist/table-complete/question.service';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styles: [
  ]
})
export class SessionListComponent implements OnInit {
 


  constructor(public service: QuerstionService) {

  }
 
  ngOnInit(): void {
  }

}
