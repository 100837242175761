<ng-template
    #broadcastMessage
    let-modal
>
    <div class="modal-header">
        <h4
            class="modal-title"
            id="modal-basic-title"
        >Rundnachricht versenden</h4>
    </div>
    <div class="modal-body">
        <textarea
            #message
            class="form-control"
            rows="3"
        ></textarea>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-secondary"
            (click)="modal.close()"
        >Abbrechen</button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="service.broadcastMessage(tokenObject.sessionIdentifier, message.value) && modal.close()"
        >Absenden</button>
    </div>
</ng-template>

<ng-container>

    <td style="vertical-align:middle">
        <ng-container *ngIf="tokenObject.fullMode">
            <ng-container
                *ngFor="let item of service.sidToConnectionState.get(tokenObject.sessionIdentifier) | keyvalue"
            >
                <div
                    class="float-left circle col"
                    mdbTooltip="Verbindungsstatus: Ausgezeichnet"
                    style="background-color: #28a745;"
                    *ngIf="item.key === 'Excellent'"
                >
                    {{item.value}}</div>
                <div
                    class="float-left circle col"
                    mdbTooltip="Verbindungsstatus: Mittel"
                    style="background-color: #ffc107;"
                    *ngIf="item.key === 'Fair'"
                >
                    {{item.value}}</div>
                <div
                    class="float-left circle col"
                    mdbTooltip="Verbindungsstatus: Schlecht"
                    style="background-color: #dc3545;"
                    *ngIf="item.key === 'Poor'"
                >
                    {{item.value}}</div>
            </ng-container>
        </ng-container>

    </td>
    <td style="vertical-align:middle"><a
            mdbTooltip="Meetingraum öffnen"
            class=""
            target="_blank"
            [href]="getRoomLinkFromToken(tokenObject)"
        >{{this.service.getSessionIdentifierNamedByToken(tokenObject)}}</a>
    </td>
    <td style="vertical-align:middle">
        <a
            class=""
            target="_blank"
            *ngIf="this.isTokenExpired"
            [mdbTooltip]="minutesUntilExpiry"
            [href]="getRoomLinkFromToken(tokenObject)"
        >
            Sitzung abgelaufen, verwenden Sie einen neuen link aus dem Meetingraum
        </a>

        <div
            class=" form-check form-switch d-inline p-0"
            *ngIf="service.sidToConnected.get(tokenObject.sessionIdentifier)"
        >
            <label
                class="form-check-label "
                for="hideTN"
                style="width:290px"
            >
                Teilnehmernamen verbergen </label>
            <input
                class="form-check-input"
                type="checkbox"
                id="hideTN"
                #showParticipantNames
                [checked]="!this.showNames.participant"
                (change)="showNamesChanged(!showModNames.checked, !showParticipantNames.checked)"
            >
            <label
                class="form-check-label "
                for="hideMOD"
                style="width:290px"
            >
                Moderatorennamen verbergen </label>
            <input
                class="form-check-input"
                type="checkbox"
                id="hideMOD"
                #showModNames
                [checked]="!this.showNames.moderator"
                (change)="showNamesChanged(!showModNames.checked, !showParticipantNames.checked)"
            >
        </div>
    </td>

    <td>
        <div
            class="btn btn-sm btn-outline-secondary"
            *ngIf="tokenObject.fullMode && service.sidToConnected.get(tokenObject.sessionIdentifier)"
            mdbTooltip="Rundnachricht versenden"
            (click)="openModal(broadcastMessage)"
        ><i class="ri-feedback-fill"></i>
        </div>
        <div
            class="btn btn-sm btn-outline-secondary m-1"
            mdbTooltip="Verbindung aktiv"
            *ngIf="tokenObject.fullMode && (service.sidToConnected.get(tokenObject.sessionIdentifier))"
            (click)="service.leaveModeratorClient(tokenObject, false)"
        ><i class="ri-eye-line"></i></div>
        <div
            class="btn btn-sm btn-outline-secondary m-1"
            mdbTooltip="Verbindung deaktiviert"
            *ngIf="tokenObject.fullMode && !this.isTokenExpired  && !(service.sidToConnected.get(tokenObject.sessionIdentifier))"
            (click)="service.joinModeratorClient(service.sidToToken.get(tokenObject.sessionIdentifier))"
        >
            <i class="ri-eye-close-line"></i>
        </div>
        <div
            style="margin-right: -19px;"
            class="btn btn-sm btn-outline-danger"
            *ngIf="tokenObject.fullMode"
            mdbTooltip="Sitzung Verlassen"
            (click)="this.promptAndLeaveSession()"
        ><i class="ri-close-circle-fill"></i>
        </div>
    </td>



</ng-container>