import { ConnectSocketData, TokenData, UserListData } from '@specialforce/spftypes';
import { SocketService } from '../shared/SocketService';
import { QuerstionService } from './table-complete/question.service';


export class UserListHelper {

    public userList: UserListData[] = [];


    public socketService: SocketService;
    constructor(public querstionService: QuerstionService) {
        this.socketService = this.querstionService.socketService;
        this.socketService.socket.on("updateConnectClientSocket", this.updateConnectClientSocket.bind(this))
        this.socketService.socket.on("addConnectClientSocket", this.addConnectClientSocket.bind(this))
        this.socketService.socket.on("removeConnectClientSocket", this.removeConnectClientSocket.bind(this))
    }

    public getUserListbySessionIdentifier(sessionIdentifier: string): UserListData[] {
        return this.userList.filter((u) => u.sessionIdentifier == sessionIdentifier)
    }
    public getUserListSessionAndUserId(sessionIdentifier: string, userId: string): UserListData[] {
        return this.userList.filter((u) => u.sessionIdentifier == sessionIdentifier && u.userId == userId)
    }

    public myClientLeft(sessionIdentifier: string) {
        this.removeSessionIdentifierFromList(sessionIdentifier)

    }
    public myClientJoind(sessionIdentifier: string) {
        this.updateCompleteUserList(sessionIdentifier)
    }
    public myClientConnected() { }

    public updateConnectClientSocket(user: UserListData) {
        const data = this.getUserDataBySocketId(user.socketid)
        Object.assign(data, user)
    }
    public addConnectClientSocket(user: UserListData) {
        console.log("Add user to list", user)
        this.userList.push(user)

    }
    public removeConnectClientSocket(user: UserListData) {
        this.removeSocketIdFromList(user.socketid)
    }


    public updateCompleteUserList(sessionIdentifier: string) {
        this.socketService.socket.emit("getConnectClientSockets", sessionIdentifier, (userList: UserListData[]) => {
            console.log("get Complete UserList", { sessionIdentifier, userList })
            this.removeSessionIdentifierFromList(sessionIdentifier);
            this.userList.push(...userList)
        })
    }

    public removeSocketIdFromList(socketid: string) {
        for (var i = 0; i < this.userList.length; i++) {
            if (this.userList[i].socketid === socketid) {
                this.userList.splice(i, 1);
                i--;
            }
        }
    }
    public removeSessionIdentifierFromList(sessionIdentifier: string) {
        for (var i = 0; i < this.userList.length; i++) {
            if (this.userList[i].sessionIdentifier === sessionIdentifier) {
                this.userList.splice(i, 1);
                i--;
            }
        }
    }

    public getUserDataBySocketId(socketid: string): UserListData {
        return this.userList.find((userData: UserListData) => userData.socketid === socketid)
    }


}