<ng-template
    #notificationRequest
    let-modal
>
    <div class="modal-header">
        <h4
            class="modal-title"
            id="modal-basic-title"
        >Desktopbenachrichtigung erlauben</h4>
    </div>
    <div class="modal-body">
        <p>
            <b>Möchten Sie erlauben, dass Desktopbenachrichtigungen angezeigt werden?</b><br>
            Dadurch können wir Ihnen bei neuen Fragen und Antworten eine außerhalb des Browsers sichtbare
            Benachrichtigung schicken.
            Eventuell müssen Sie das Anzeigen der Benachrichtigungen im Browser noch einmal separat zulassen.
        </p>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-secondary"
            (click)="modal.close('deny')"
        >Ablehnen</button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="modal.close('accept')"
        >Akzeptieren</button>
    </div>
</ng-template>
<ng-template
    #sosModal
    let-modal
>
    <div class="modal-header">
        <h5
            class="modal-title"
            id="exampleModalLabel"
        >Hilfeanfrage</h5>
        <button
            type="
              button"
            class="btn-close"
            aria-label="Close"
            (click)="modal.close()"
        ></button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col">
                <p>{{msg}}</p>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a
            target="_blank"
            [href]="getRoomLinkFromToken(tokenObject)"
            class="btn btn-outline-success btn-sm"
            (click)="modal.close()"
        >
            Raum öffnen</a>
    </div>
</ng-template>
<router-outlet></router-outlet>