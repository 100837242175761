<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Frage von {{question.creatorName}} <app-connect-user-data-indikator
      [userListDatas]=" this.tableCompleteComponent.service.userListHelper.getUserListSessionAndUserId(question.sessionIdentifier, question.creatorIdentifier)">
    </app-connect-user-data-indikator>
  </h5>
  <button type="
          button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <lib-ng-qa-question-renderer [question]="question"></lib-ng-qa-question-renderer>
      <textarea class="d-block form-control" #textarea
        [placeholder]="(question.workedByStr && question.workedByStr != tableCompleteComponent.service.sidToTokenObject.get(question.sessionIdentifier).userName) ? question.workedByStr + ' schreibt gerade...' : 'Antwort'"></textarea>
      <div class="row">
        <div class="col-6">
          <div class="btn-primary btn btn-sm mt-1" (click)="sendAnswer(textarea, true)">
            Öffentlich Antworten
          </div>
        </div>
        <div class="col-2"></div>
        <div class="col-4">
          <div class="btn-success btn btn-sm mt-1" (click)="sendAnswer(textarea, false)">
            Privat Antworten
          </div>
        </div>
      </div>
      <br>
    </div>
    <div class="col bg-light" style="height:400px;overflow:auto;border:5px solid #F6F7F9">
      <lib-ng-qa-question-renderer [question]="item" showAnswers='true'
        *ngFor="let item of this.tableCompleteComponent.service.getQuestionsFromSameUser(question)">
      </lib-ng-qa-question-renderer>
    </div>
  </div>


</div>
<div class="modal-footer">
  <div class="mr-auto w-25">
    <div class="row">

      <div class="col">
        <app-assigned-to-identifier [question]="question"></app-assigned-to-identifier>
      </div>
    </div>
  </div>
  <button type="button" *ngIf="question.isDeleted" (click)="this.tableCompleteComponent.service.unDelete(question)"
    class="btn btn-outline-danger btn-sm">
    <i class="ri-arrow-go-back-line" style="position:relative;top:3px"></i>
    Wiederherstellen</button>
  <button type="button" *ngIf="!question.isDeleted" (click)="this.openModal(question,'delete')"
    class="btn btn-outline-danger btn-sm">Löschen</button>
  <button type="button" (click)="modalRef.close()" class="btn btn-primary btn-sm">Schließen</button>
</div>