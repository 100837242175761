import { HostListener, Injectable, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Howl, Howler } from 'howler';
import { MODERATORS, QDataObject, QUESTIONS } from './dataModels';
import { DecimalPipe } from '@angular/common';
import { debounceTime, delay, switchMap, tap } from 'rxjs/operators';
import { SortColumn, SortDirection } from './sortable.directive';
import { AData, ConnectSocketData, ModeratorUser, QData, RoomConnectionState, ShowNames, TokenData } from '@specialforce/spftypes';
import { SocketService } from 'src/app/shared/SocketService';
import { NotificationService } from 'src/app/shared/NotificationService';
import jwt_decode from 'jwt-decode';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserListHelper } from '../UserListHelper';

interface SearchResult {
  questions: QData[];
  total: number;
}
export type FilterType = "all" | "open" | "new" | "my" | "done" | "deleted" | string;

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: SortColumn;
  sortDirection: SortDirection;
}

const compare = (v1: string | number, v2: string | number) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

@Injectable({ providedIn: 'root' })
export class QuerstionService {
  public currentFilter: FilterType = "all";
  public isDisconnected: boolean = false;

  public userListHelper: UserListHelper;

  public sidToToken: Map<string, string> = new Map();
  public sidToTokenObject: Map<string, ConnectSocketData> = new Map();
  public sidToShowNames: Map<string, ShowNames> = new Map();
  public sidToConnectionState: Map<string, RoomConnectionState> = new Map();
  public sidToConnected: Map<string, boolean> = new Map();
  public audionotification: boolean = false;
  public desknotification: boolean = false;

  public moderatorsChanged = new Subject<void>();
  public showNamesChanged = new Subject<void>();

  private sound: Howl;
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _questions$ = new BehaviorSubject<QData[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 100,
    searchTerm: '',
    sortColumn: 'creationTimestamp',
    sortDirection: 'desc'
  };
  constructor(private pipe: DecimalPipe, public socketService: SocketService, private notificationService: NotificationService) {
    this.userListHelper = new UserListHelper(this)

    this._search$.pipe(
      tap(() => this._loading$.next(true)),
      debounceTime(200),
      switchMap(() => this._search()),
      delay(200),
      tap(() => this._loading$.next(false))
    ).subscribe(result => {
      this._questions$.next(result.questions);
      this._total$.next(result.total);
    });

    this._search$.next();
    this.sound = new Howl({
      src: ['assets/bling2.mp3']
    });
    const notification = localStorage.getItem('QA-External-Notifications');
    if (notification) {
      const x = JSON.parse(notification);
      this.audionotification = x.audio;
      this.desknotification = x.desktop;
    }
    this.socketService.socket.on('connect', this.onConnect.bind(this));
    this.socketService.socket.on('disconnect', () => this.isDisconnected = true);

    this.socketService.socket.on('questionAdded', this.onQuestionAdded.bind(this));
    this.socketService.socket.on('workingOn', this.onWorkingOn.bind(this));
    this.socketService.socket.on('workingOff', this.onWorkingOff.bind(this));
    this.socketService.socket.on('questionAssigned', this.onQuestionAssigned.bind(this));
    this.socketService.socket.on('questionDeletedChanged', this.onQuestionDeletedChanged.bind(this));
    this.socketService.socket.on('answerAdded', this.onAnswerAdded.bind(this));
    this.socketService.socket.on('moderatorJoined', this.onModeratorJoined.bind(this));
    this.socketService.socket.on('moderatorLeft', this.onModeratorLeft.bind(this));
    this.socketService.socket.on('showNamesChanged', this.onShowNamesChanged.bind(this));
    this.socketService.socket.on('connectionStateUpdate', this.onConnectionStateUpdate.bind(this))
  }

  public get applicationMode(): "light" | "full" {
    return this.socketService.applicationMode;
  }
  private async joinInitalFromLocalStorage() {

    if (this.applicationMode === "light" || this.socketService.lightModeToken != null) {
      this.socketService.applicationMode = "light"
      this.joinModeratorClient(this.socketService.lightModeToken)
      return
    }
    const item: string = localStorage.getItem('QA-External-Moderatortokens');
    console.log('inital', item);
    if (item) {
      const moderatorTokens: string[] = JSON.parse(item);
      moderatorTokens.forEach((token) => {
        this.joinModeratorClient(token);
      })
    }
  }
  private getQuestionObjectFromId(questionId: string): QDataObject {
    const res = QUESTIONS.find(qo => qo.id === questionId);
    return res;
  }
  private onConnect() {
    console.log('connect now');
    this.isDisconnected = false;
    this.socketService.socket.sendBuffer = [];
    this.joinInitalFromLocalStorage();
  }

  public async joinModeratorClient(token: string) {

    const decoded: TokenData = jwt_decode(token)
    console.log('tokk', decoded);
    this.sidToTokenObject.set(decoded.sessionIdentifier, decoded);
    this.sidToToken.set(decoded.sessionIdentifier, token);
    if (decoded.exp < new Date().getTime() / 1000) {
      console.log("token abgelaufen cannogt join")
      return
    }

    const worked = await this.socketService.joinModeratorClient(token)
    this.userListHelper.myClientJoind(decoded.sessionIdentifier)
    console.log("join moderatror done user worked ", worked);

    this.sidToConnected.set(decoded.sessionIdentifier, true);
    this.getModeratorListCb(await this.socketService.moderatorList());
    this.socketService.socket.emit('getQuestionList', (questions: QData[], workedByArray: any[]) => this.getQuestionListCb(questions, workedByArray));
    const showNames = await this.socketService.getShowNames(decoded.sessionIdentifier)
    this.sidToShowNames.set(decoded.sessionIdentifier, showNames);
  }
  public async leaveModeratorClient(tokenObject: ConnectSocketData, permanent: boolean, updateLocalStorage: boolean = true) {
    console.log("token tio leave", tokenObject.sessionIdentifier)
    const token: string = this.sidToToken.get(tokenObject.sessionIdentifier);

    if (!this.sidToConnected.get(tokenObject.sessionIdentifier)) { // wenn der socket wegen abgelaufen sesseion eh nicht connected ist
      if (permanent) {
        this.sidToTokenObject.delete(tokenObject.sessionIdentifier);
        this.sidToToken.delete(tokenObject.sessionIdentifier);
        if (updateLocalStorage) { // Wird nicht gemacht wenn das Update schon über ein localstorage update kam
          const deleted = this.deleteTokenFromLocalStorage(token);
        }
      }
    }
    const worked = await this.socketService.leaveModeratorClient(token);
    if (worked) {
      this.userListHelper.myClientLeft(tokenObject.sessionIdentifier)
      console.log('questions before', JSON.parse(JSON.stringify(QUESTIONS)));
      const questions: QDataObject[] = QUESTIONS.filter(q => q.sessionIdentifier !== tokenObject.sessionIdentifier);
      QUESTIONS.splice(0);
      QUESTIONS.push(...questions);
      this._search$.next();
      console.log('questions after', JSON.parse(JSON.stringify(QUESTIONS)));
      this.sidToConnected.set(tokenObject.sessionIdentifier, false);
      if (permanent) {

        this.sidToTokenObject.delete(tokenObject.sessionIdentifier);
        this.sidToToken.delete(tokenObject.sessionIdentifier);
        if (updateLocalStorage) { // Wird nicht gemacht wenn das Update schon über ein localstorage update kam
          const deleted = this.deleteTokenFromLocalStorage(token);
        }
      }
    }
    return
  }
  private deleteTokenFromLocalStorage(token: string): boolean {
    if (this.socketService.applicationMode == "light") {
      return false
    }
    const item: string = localStorage.getItem('QA-External-Moderatortokens');
    const array: string[] = JSON.parse(item);
    let index: number;
    if (array && array.length > 0 && (index = array.indexOf(token)) > -1) {
      array.splice(index, 1);
      localStorage.setItem('QA-External-Moderatortokens', JSON.stringify(array));
      return true;
    }
    return false;
  }


  private onShowNamesChanged(showNames: ShowNames, sessionIdentifier: string) {
    console.log(sessionIdentifier, showNames)
    this.sidToShowNames.set(sessionIdentifier, showNames);
    this.showNamesChanged.next()
  }
  private onModeratorJoined(moderator: ModeratorUser) {

    this.checkIfInModeratorsArrayOrPush(moderator.userId, moderator.userName, moderator.sessionIdentifier)
    console.log('mod joined', moderator);
    //MODERATORS.push(moderator);
  }
  private onModeratorLeft(moderator: ModeratorUser) {
    const index = MODERATORS.findIndex(m => m.userId === moderator.userId && m.sessionIdentifier === moderator.sessionIdentifier);
    if (index > -1) {
      MODERATORS.splice(index, 1);
      this.moderatorsChanged.next()
    }
  }
  private getQuestionListCb(questions: QData[], workedByArray: { questionId: string, socketId: string, userName: string }[]) {
    console.log('q', questions);
    QUESTIONS.splice(0);
    questions.forEach(q => {
      QUESTIONS.push(new QDataObject(q));
      this.checkIfInModeratorsArrayOrPush(q.assignedToIdentifier, q.assignedToName, q.sessionIdentifier);
    });
    workedByArray.forEach(w => {
      this.onWorkingOn(w.questionId, w.socketId, w.userName);
    });
    this._search$.next();
  }
  private getModeratorListCb(moderators: ModeratorUser[]) {
    console.log('mpd', moderators);
    moderators.forEach(mod => {
      this.checkIfInModeratorsArrayOrPush(mod.userId, mod.userName, mod.sessionIdentifier);
    });
  }
  private onQuestionAssigned(questionId: string, assignedUserId: string, assignedUserName: string) {
    console.log("RECIEVED QUESTION ASSIGNED", assignedUserName + " " + questionId + ":" + new Date())
    const q = this.getQuestionObjectFromId(questionId);
    q.assignedTo(assignedUserId);
    q.assignToName(assignedUserName);
    this.checkIfInModeratorsArrayOrPush(assignedUserId, assignedUserName, q.sessionIdentifier);
    if (assignedUserId === this.sidToTokenObject.get(q.sessionIdentifier).userId) {
      this.playSoundIfAudioOn();
      this.sendNotificationIfAllowed('Ihnen wurde eine Frage zugewiesen.');
    }
  }
  private checkIfInModeratorsArrayOrPush(id: string, name: string, sessionIdentifier: string) {
    if (!id || id === '' || !name || name === '') return;
    if (!MODERATORS.some(m => m.userId === id && m.sessionIdentifier === sessionIdentifier)) {
      MODERATORS.push({ userId: id, userName: name, sessionIdentifier: sessionIdentifier });
      this.moderatorsChanged.next()
    }
  }
  private onQuestionDeletedChanged(questionId: string, deleted: boolean) {
    const q = this.getQuestionObjectFromId(questionId);
    if (deleted) {
      q.delete();
    } else {
      q.unDelete();
    }
    this._search$.next();
  }
  private onAnswerAdded(answer: AData, question?: Omit<QData, "answers">) {
    const found = QUESTIONS.find(qo => qo.id === question._id);
    console.log('new anwer', found);
    if (found) {
      found.addAnswer(answer);
    }
  }
  private onWorkingOn(questionId: string, socketId: string, userName: string) {
    const found = QUESTIONS.find(qo => qo.id === questionId);
    if (found) {
      const u = {
        socketId,
        userName
      };
      if (!found.workedByArray.some(w => w.socketId === u.socketId)) found.workedByArray.push(u);
    }
  }
  private onWorkingOff(questionId: string, socketId: string, userName: string) {
    const found = QUESTIONS.find(qo => qo.id === questionId);
    if (found) {
      const index = found.workedByArray.findIndex(w => w.socketId === socketId)
      if (index > -1) found.workedByArray.splice(index, 1);
    }
  }
  private onQuestionAdded(question: QData) {
    const questionObject: QDataObject = new QDataObject({
      _id: question._id,
      creatorIdentifier: question.creatorIdentifier,
      creatorName: question.creatorName,
      creationTimestamp: question.creationTimestamp,
      text: question.text,
      assignedToIdentifier: question.assignedToIdentifier,
      isDeleted: question.isDeleted,
      answers: question.answers,
      sessionIdentifier: question.sessionIdentifier,
    });
    QUESTIONS.push(questionObject);
    this._search$.next();
    this.playSoundIfAudioOn();
    this.sendNotificationIfAllowed('Es wurde eine neue Frage gestellt.');
  }
  get questions$() { return this._questions$.asObservable(); }
  get total$() { return this._total$.asObservable(); }
  get loading$() { return this._loading$.asObservable(); }
  get page() { return this._state.page; }
  get pageSize() { return this._state.pageSize; }
  get searchTerm() { return this._state.searchTerm; }

  set page(page: number) { this._set({ page }); }
  set pageSize(pageSize: number) { this._set({ pageSize }); }
  set searchTerm(searchTerm: string) { this._set({ searchTerm }); }
  set sortColumn(sortColumn: SortColumn) { this._set({ sortColumn }); }
  set sortDirection(sortDirection: SortDirection) { this._set({ sortDirection }); }

  public getNonPaginatedFilteredquestions(): QDataObject[] {

    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    let questions = [...QUESTIONS]
    questions = questions.filter(question => this.matchFilter(question, this.currentFilter));
    // 1. sort
    questions = this.sort(questions, sortColumn, sortDirection);

    // 2. filter
    questions = questions.filter(question => this.matches(question, searchTerm, this.pipe));
    console.log("currentfilter", { cf: this.currentFilter, QUESTIONS, questions })
    return questions
  }
  filter(type: FilterType) {
    this.currentFilter = type;
    this._search$.next();
  }

  public countFilter(type: FilterType) {
    let questions = [...QUESTIONS]
    questions = questions.filter(question => this.matchFilter(question, type));
    return questions.length
  }

  public answer(question: QDataObject, text: string, isPublicAnswer: boolean = false) {
    this.socketService.socket.emit('newAnswer', question.id, text, isPublicAnswer);
  }
  delete(question: QDataObject) {
    this.socketService.socket.emit('questionDeletedChanged', question.id, true);
  }
  unDelete(question: QDataObject) {
    this.socketService.socket.emit('questionDeletedChanged', question.id, false);
  }
  questionAssigned(question: QDataObject, assignedUserId: String, assignedUserName: string) {
    console.log("EMIT QUESTION ASSIGNED", assignedUserName + " " + question.id + ":" + new Date())
    this.socketService.socket.emit('questionAssigned', question.id, assignedUserId, assignedUserName);
  }
  workingOn(question: QDataObject) {
    this.socketService.socket.emit('workingOn', question.id);
  }
  workingOff(question: QDataObject) {
    this.socketService.socket.emit('workingOff', question.id);
  }
  broadcastMessage(sessionIdentifier: string, message: string) {
    console.log('broadcast', sessionIdentifier, message);
    this.socketService.socket.emit('broadcastMessage', sessionIdentifier, message);
    return true;
  }
  public setShowNames(sessionIdentifier: string, showNames: ShowNames) {
    this.socketService.socket.emit('setShowNames', sessionIdentifier, showNames);
  }
  public getQuestionsFromSameUser(question: QData) {
    let questions = [...QUESTIONS];
    questions = questions.filter(q => q.creatorName == question.creatorName);
    questions.sort(this.sortByTime.bind(this));
    return questions;
  }
  private sortByTime(a: QDataObject, b: QDataObject): number {
    return a.lastTime - b.lastTime;
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    console.log('state', this._state);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;
    let questions = [...QUESTIONS]
    questions = questions.filter(question => this.matchFilter(question, this.currentFilter));
    // 1. sort
    questions = this.sort(questions, sortColumn, sortDirection);

    // 2. filter
    questions = questions.filter(question => this.matches(question, searchTerm, this.pipe));
    const total = questions.length;

    // 3. paginate
    questions = questions.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ questions, total });
  }
  private matchFilter(question: QDataObject, type: FilterType): boolean {

    switch (type) {
      case "all":
        if (question.isDeleted) { return false }
        return true;
      case "open":
        if (question.isDeleted) { return false }
        if (question.isAnswered) { return false }
        return true;
      case "new":
        if (question.isDeleted) { return false }
        if (question.isAnswered) { return false }
        if (question.assignedToIdentifier) { return false }
        return true;
      case "my":
        if (question.isDeleted) { return false }
        if (question.isAnswered) { return false }
        if (this.sidToTokenObject.has(question.sessionIdentifier) && this.sidToTokenObject.get(question.sessionIdentifier).userId != question.assignedToIdentifier) { return false }
        return true;
      case "done":
        if (question.isDeleted) { return false }
        if (question.isAnswered) { return true }
        return false;


      case "deleted":
        return question.isDeleted


      default:
        return false;


    }
  }

  public getSessionIdentifierNamedByToken(token: ConnectSocketData): string {

    if (token) {
      return token.sessionName + "@" + this.trimConnectDomain(token.connectDomain);
    }
    return '';
  }


  public getSessionIdentifierNamedByQuestion(question: QData): string {
    const token = this.sidToTokenObject.get(question.sessionIdentifier);
    return this.getSessionIdentifierNamedByToken(token)
  }

  public trimConnectDomain(connectDomain: string): string {
    try {
      return connectDomain.replace("https://", "").replace("http://", "").replace(".reflact.com", "").replace(".adobeconnect.com", "")
    } catch (e) { }
    return ""
  }

  private sort(questions: QDataObject[], column: SortColumn, direction: string): QDataObject[] {
    if (direction === '' || column === '') {
      return questions;
    } else {
      return [...questions].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }
  private matches(question: QDataObject, term: string, pipe: PipeTransform) {
    return question.text.toLowerCase().includes(term.toLowerCase())

  }
  public playSoundIfAudioOn() {
    if (this.audionotification) this.sound.play();
  }
  public sendNotificationIfAllowed(title: string, options?: NotificationOptions) {
    if (this.desknotification && this.notificationService.notificationsPossible && Notification.permission === 'granted') {
      new Notification(title, options);
    }
  }
  public async tokenArrayChanged(oldArray: string[], newArray: string[]) {
    if (this.socketService.applicationMode === "light") {
      return
    }
    const newTokens: string[] = newArray.filter(x => !oldArray.includes(x));
    const deletedTokens: string[] = oldArray.filter(x => !newArray.includes(x));

    if (deletedTokens && deletedTokens.length > 0) {
      for (let i = 0; i < deletedTokens.length; i++) {
        const token = deletedTokens[i]
        const decoded: ConnectSocketData = jwt_decode(token);
        await this.leaveModeratorClient(decoded, true, false);
      }
    }
    if (newTokens && newTokens.length > 0) newTokens.forEach(token => this.joinModeratorClient(token));

  }
  private onConnectionStateUpdate(roomState: RoomConnectionState, sessionIdentifier: string) {
    this.sidToConnectionState.set(sessionIdentifier, roomState);
  }
}