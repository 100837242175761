import { Injectable } from '@angular/core';
import { QuerstionService } from '../questionlist/table-complete/question.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private appPermission = true;

    constructor() {
        let appPermission = localStorage.getItem('NotificationPermission');
        if (appPermission !== null) {
            this.appPermission = appPermission === 'true';
        }
    }

    public disableAppPermission() {
        localStorage.setItem('NotificationPermission', 'false');
        this.appPermission = false;
    }

    public async requestPermission(): Promise<NotificationPermission> {
        const permission: NotificationPermission = await Notification.requestPermission();
        return permission;
    }

    public notificationsPossible() {
        return "Notification" in window;
    }

    public showNotificationRequest() {
        return this.notificationsPossible() && Notification.permission === 'default' && this.appPermission === true;
    }
}
