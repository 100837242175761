import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { QuerstionService } from '../questionlist/table-complete/question.service';
import jwt_decode from 'jwt-decode';
import { ConnectSocketData, TokenData } from '@specialforce/spftypes';
import { SocketService } from './SocketService';

@Injectable()
export class TokenResolver implements Resolve<any> {
    constructor(private route: ActivatedRoute, private router: Router, private socketService: SocketService, private service: QuerstionService) {
    }

    async resolve(route: ActivatedRouteSnapshot) {
        const token: string = route.params.token;
        const tokenObject: TokenData = jwt_decode(token)
        console.log("tokenobject fullmode", tokenObject.fullMode)
        if (!tokenObject.fullMode) {
            this.socketService.applicationMode = "light"
            this.socketService.lightModeToken = token
            console.log("setting applicaitonmode to light")
            this.router.navigate(['list', token]);
            return token
        }
        this.socketService.applicationMode = "full"
        let array: string[] = [];
        let item: string;
        if (item = localStorage.getItem('QA-External-Moderatortokens')) {
            array = JSON.parse(item);
        }

        let isTokenUpdated = false
        for (let i = 0; i < array.length; i++) { // replace token with lower exp
            const t: TokenData = jwt_decode(array[i])
            if (t.sessionIdentifier == tokenObject.sessionIdentifier) {
                isTokenUpdated = true
                if (t.exp < tokenObject.exp) {
                    array[i] = token
                    console.log("updateing token", t)
                    console.log("with token", tokenObject)

                }

            }
        }
        if (!isTokenUpdated) array.push(token);
        localStorage.setItem('QA-External-Moderatortokens', JSON.stringify(array));

        console.log('resolver done', localStorage.getItem('QA-External-Moderatortokens'));
        this.router.navigate(['list']);
        return token;
    }

}
