import { DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { QData } from '@specialforce/spftypes';
import { MdbModalRef } from 'mdb-angular-ui-kit';
import { QDataObject } from '../../dataModels';
import { QuerstionService } from '../../question.service';
import { TableCompleteComponent } from '../../table-complete.component';

import { QuestionModalComponent } from '../question-modal/question-modal.component';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styles: [
  ]
})
export class DeleteModalComponent implements OnInit {
  question: QDataObject;
  public tableCompleteComponent: TableCompleteComponent;
  constructor(public modalRef: MdbModalRef<QuestionModalComponent>) { }

  ngOnInit(): void {
  }

}
