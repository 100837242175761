import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConnectSocketData, ShowNames, TokenData } from '@specialforce/spftypes';
import { QuerstionService } from 'src/app/questionlist/table-complete/question.service';

@Component({
  selector: '[app-session-list-row]',
  templateUrl: './session-list-row.component.html',
  styleUrls: ['./session-list-row.component.css']
})
export class SessionListRowComponent implements OnInit, OnChanges {
  public settingsMenu = false;
  @Input() tokenObject: TokenData;
  @ViewChild('broadcastMessage') broadcastMessage;

  public showNames: ShowNames = { moderator: false, participant: false }

  public isTokenExpired: boolean = false;
  public minutesUntilExpiry = 0
  modalRef: NgbModalRef;
  constructor(public service: QuerstionService, private modalService: NgbModal) {
    this.service.showNamesChanged.subscribe(data => this.updateShowNames())
  }
  public getRoomLinkFromToken(tokenObject: any) {
    if (tokenObject.roomLink != null) {
      return tokenObject.roomLink
    }
    return tokenObject.connectDomain + "" + tokenObject.connectRoomPath
  }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges) {
    this.updateShowNames()
    this.isTokenExpired = this.tokenObject.exp < new Date().getTime() / 1000
    this.minutesUntilExpiry = (this.tokenObject.exp - new Date().getTime() / 1000) / 60
    this.tokenObject.exp < new Date().getTime() / 1000
    console.log("isToken Expired", this.isTokenExpired)
  }
  private updateShowNames() {
    const sn = this.service.sidToShowNames.get(this.tokenObject.sessionIdentifier)
    if (sn != null) {
      this.showNames = sn
    }
    console.log("new show names", this.showNames)

  }


  showNamesChanged(modChecked: boolean, participantChecked: boolean) {
    const showNames = {
      participant: participantChecked,
      moderator: modChecked
    };

    console.log("setShowNames", showNames)
    this.service.setShowNames(this.tokenObject.sessionIdentifier, showNames);
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.open(template);
  }

  public promptAndLeaveSession() {
    if (confirm("Diese Sitzung verlassen? Sie können die Sitzung mit ihrem Link später wieder hinzufügen.")) {
      this.service.leaveModeratorClient(this.tokenObject, true)
    }
  }


}
